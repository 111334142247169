<template>
    <div>
        <div class="border-primary border-b-2 mb-8">
            <div class="bg-primary text-grays-50 text-lg font-semibold p-2 w-max rounded-sm"> {{ $t('news.title1') }}</div>
        </div>
        <div class="flex md:justify-between flex-wrap flex-col md:flex-row">
            <div 
                class="flex relative items-end text-left shadow-sm rounded-sm h-64 cursor-pointer  mb-2 md:mb-0 md:h-auto w-full md:w-1/3 md:mr-2 sm:flex-initial bg-cover bg-center"
                :style="{ backgroundImage: `url(${art[0].image})` }"
                @click="handleArt(art[0].hash)"
            >
                <div class="bg-mocha w-full p-3" :title=art[0].title>
                    <div class="text-grays-50 text-xs flex item-center">
                        <div class="bg-secondary mr-2 h-5 w-12 grid place-items-center rounded-sm capitalize">{{art[0].type}}</div>
                        <div class="h-5 w-2/3 text-left flex items-center">{{art[0].created_at}}</div>
                    </div>
                   
                    <div class=" text-grays-50 text-base  md:text-lg lg:text-xl font-semibold leading-none mt-3 truncate">
                        <span v-if="lang=='id'">{{art[0].title}}</span>
                        <span v-else>{{art[0].title_eng}}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col justify-between w-full md:w-46%">
                <div class="rounded-sm mb-2 h-56 flex items-end cursor-pointer  bg-cover bg-center" 
                    :style="{ backgroundImage: `url(${art[1].image})` }"
                    @click="handleArt(art[1].hash)"
                >
                    <div class="bg-mocha w-full p-3 text-left" :title=art[1].title>
                        <div class="text-grays-50 text-xs flex item-center">
                            <div class="bg-secondary mr-2 h-5 w-12 grid place-items-center rounded-sm capitalize">{{art[1].type}}</div>
                            <div class="h-5 w-2/3 text-left flex items-center">{{art[1].created_at}}</div>
                        </div>
                        <div class=" text-grays-50 text-base  md:text-lg lg:text-xl font-semibold leading-none mt-3 truncate">
                            <span v-if="lang=='id'">{{art[1].title}}</span>
                            <span v-else>{{art[1].title_eng}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex items-center h-56">
                    <div class="shadow-lg w-3/6 mr-1 flex items-end cursor-pointer  rounded-sm bg-cover bg-center h-full" 
                        :style="{ backgroundImage: `url(${art[2].image})` }"
                        @click="handleArt(art[2].hash)"
                    >
                        <div class="bg-mocha w-full p-3 text-left" :title=art[2].title>
                            <div class="text-grays-50 text-xs flex item-center">
                                <div class="bg-secondary mr-2 h-5 w-12 grid place-items-center rounded-sm capitalize">{{art[2].type}}</div>
                                <div class="h-5 w-2/3 text-left flex items-center">{{art[2].created_at}}</div>
                            </div>
                            <div class="text-grays-50 text-base  md:text-lg lg:text-xl font-semibold leading-none mt-3 truncate">
                                <span v-if="lang=='id'">{{art[2].title}}</span>
                                <span v-else>{{art[2].title_eng}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="shadow-lg w-3/6 ml-1 flex items-end cursor-pointer  rounded-sm bg-cover bg-center h-full" 
                        :style="{ backgroundImage: `url(${art[3].image})` }"
                        @click="handleArt(art[3].hash)"
                    >
                        <div class="bg-mocha w-full p-3 text-left" :title=art[3].title>
                            <div class="text-grays-50 text-xs flex item-center">
                                <div class="bg-secondary mr-2 h-5 w-12 grid place-items-center rounded-sm capitalize">{{art[3].type}}</div>
                                <div class="h-5 w-2/3  text-left flex items-center">{{art[3].created_at}}</div>
                            </div>
                            <div class=" text-grays-50 text-base  md:text-lg lg:text-xl font-semibold leading-none mt-3 truncate">
                                <span v-if="lang=='id'">{{art[3].title}}</span>
                                <span v-else>{{art[3].title_eng}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <ul class="flex  items-center  border-secondary border-b-2 mt-10">
            <li 
                class="bg-border text-secondary border border-secondary px-2 mr-8 capitalize font-pop cursor-pointer flex items-center justify-center text-sm md:text-base h-10 w-44 font-bold rounded-sm hover:bg-secondary hover:text-grays-50"
                v-for="(item, index) in data" :key="index"
                :class="{'activeTabMen' : index == tabActive }"
                @click="handleTab(index)"
            >
               
                <span v-if="lang=='id'"> {{item.title}}</span>
                <span v-else> {{item.title_eng}}</span>
            </li>
            
        </ul>

        <section class="my-8">
            <div v-if="tabActive == 0" class="flex flex-wrap justify-between">
               
                <div class="w-full flex items-end md:w-23% rounded-sm cursor-pointer  bg-cover bg-center h-60 mb-6" 
                    :style="{ backgroundImage: `url(${item.image})` }" v-for="(item, i) in typeFilter" :key="i" :title="item.title"
                    @click="handleArt(item.hash)"
                >
                    <div class="bg-mocha w-full p-3 text-left">
                        <div class="text-grays-50 text-xs flex item-center">
                            <div class="h-5 w-full flex items-center text-left">{{item.created_at}}</div>
                        </div>
                        <div class=" text-grays-50 text-sm  md:text-base  font-semibold leading-none mt-1 truncate">
                            <span v-if="lang =='id'">{{item.title}}</span>
                            <span v-else>{{item.title_eng}}</span>
                        </div>
                    </div>
                </div>

                <div class="w-full flex items-end md:w-23% rounded-sm cursor-pointer  bg-cover bg-center h-4 visible" v-for="(item, i) in 2 " :key="i+'dummi'"> 
                </div>

                <!-- <div class="grid place-items-center mt-20 w-full">
                    <div class="w-36 text-links uppercase h-11 flex items-center justify-center text-sm border border-links cursor-pointer">
                        Muat Lainnya
                    </div>
                </div> -->
            </div>

            <div v-if="tabActive == 1" class="flex flex-wrap justify-between">
                
                <div class="w-full flex items-end md:w-23% rounded-sm cursor-pointer  bg-cover bg-center h-60 mb-6" 
                    :style="{ backgroundImage: `url(${item.image})` }" v-for="(item, i) in typeFilter" :key="i" :title="item.title"
                    @click="handleArt(item.hash)"
                >
                    <div class="bg-mocha w-full p-3 text-left">
                        <div class="text-grays-50 text-xs flex item-center">
                            <div class="h-5 w-full flex items-center text-left">{{item.created_at}}</div>
                        </div>
                        <div class=" text-grays-50 text-sm  md:text-base  font-semibold leading-none mt-1 truncate">
                            <span v-if="lang =='id'">{{item.title}}</span>
                            <span v-else>{{item.title_eng}}</span>
                        </div>
                    </div>
                </div>

                <div class="w-full flex items-end md:w-23% rounded-sm cursor-pointer  bg-cover bg-center h-4 " v-for="(item, i) in 2 " :key="i+'dummi'"> 
                </div>
               
                

                <!-- <div class="grid place-items-center mt-20 w-full">
                    <div class="w-36 text-links uppercase h-11 flex items-center justify-center text-sm border border-links cursor-pointer">
                        Muat Lainnya
                    </div>
                </div> -->
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            backg:"https://www.dharmap.com/assets/images/berita/Presentation17.jpg",
            data:[
                {title: "Berita", title_eng:'News'},
                {title: "Siaran Pers", title_eng:'Press conference'}
            ],
            tabActive : 0
        }
    },
    computed: {
        ...mapState({
            art: state => state.news.article ? state.news.article.data :'',
            lang: state => state.home.lang ? state.home.lang :'id',
        }),

        typeFilter(){
            if(this.art){
                if (this.tabActive == 0){
                    const news = this.art.filter((item) => {
                        return item.type == 'news'
                    })

                    return news
                }else{
                    const pers = this.art.filter((item) => {
                        return item.type == 'press'
                    })
                    
                    return pers
                }
            }
            
        }
    
    },
    methods: {
        handleTab(i){
            this.tabActive = i
        },
        async handleArt(hash){
            try {
                const payload = {
                    hash: hash
                }
                await this.$store.dispatch('news/getViewArt',payload)
                this.$router.push(
                    { 
                        name: "detail",
                        params: {id: hash}
                    }
                )


            } catch (error) {
                console.log(error)
                    this.$router.push(
                    { 
                        path: "/404"
                    }
                )
            }
            
            
        }

    },
}
</script>

<style>
    .br1{
        border :1px solid red;
    }
    .activeTabMen{
        @apply bg-secondary text-grays-50 border-secondary hover:text-grays-50;
    }

</style>

